<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Analysis of several canned-food preparations for magnesium ion gave the following data:
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td>Sample Mean percent <chemical-latex content="Mg^{2+}" /></td>
            <td>Number of Trials</td>
            <td>Deviation of Individual Results from mean</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>4.07</td>
            <td>4</td>
            <td>0.12, 0.06, 0.05, 0.11</td>
          </tr>
          <tr>
            <td>8.04</td>
            <td>3</td>
            <td>0.09, 0.08, 0.12</td>
          </tr>
          <tr>
            <td>3.77</td>
            <td>4</td>
            <td>0.02, 0.15, 0.07, 0.010</td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        (a) For Sample 1 evaluate the
        <stemble-latex content="$80\%$" />
        confidence limit of the mean.
      </p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{a)}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (b) Calculate
        <stemble-latex content="S_{pooled}" />
        from the whole set of data.
      </p>
      <calculation-input
        v-model="inputs.input2"
        class="mb-5"
        prepend-text="$\text{b)}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A1Q5',
  components: {
    CalculationInput,
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
};
</script>
